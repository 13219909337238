export const ORDER_STATUS_A = [
	{ id: 1, label: 'Chờ sản xuất', value: 'cho_san_xuat' },
	{ id: 3, label: 'Xác nhận SX', value: 'xnsx' },
	{ id: 4, label: 'Thiết kế', value: 'thiet_ke' },
	{ id: 5, label: 'Laser Tôn', value: 'laser_ton' },
	{ id: 7, label: 'Chấn Tôn', value: 'chan_ton' },
	{ id: 10, label: 'Hàn', value: 'han' },
	{ id: 11, label: 'Sơn', value: 'son' },
	{ id: 13, label: 'Ráp tôn', value: 'rap_ton' },
	{ id: 14, label: 'Bao gói', value: 'bao_goi' },
	{ id: 15, label: 'Chờ xuất', value: 'cho_xuat' },
	{ id: 16, label: 'Xuất hàng', value: 'xuat_hang' },
	{ id: 99, label: 'Hoàn thành', value: 'hoan_thanh' },
];

export const ORDER_STATUS_B = [
	{ id: 6, label: 'Laser Inox', value: 'laser_inox' },
	{ id: 8, label: 'Chấn Inox', value: 'chan_inox' },
	{ id: 9, label: 'Ráp Inox', value: 'rap_inox' },
	{ id: 12, label: 'Ăn mòn', value: 'an_mon' },
];

export const ROLES = [
	{ id: 1, label: 'Quản trị', value: 'admin' },
	{ id: 2, label: 'Kế toán', value: 'ke_toan' },
	{ id: 3, label: 'Xác nhận SX', value: 'xnsx' },
	{ id: 4, label: 'Thiết kế', value: 'thiet_ke' },
	{ id: 5, label: 'Laser Tôn', value: 'laser_ton' },
	{ id: 6, label: 'Laser Inox', value: 'laser_inox' },
	{ id: 7, label: 'Chấn Tôn', value: 'chan_ton' },
	{ id: 8, label: 'Chấn Inox', value: 'chan_inox' },
	{ id: 9, label: 'Ráp Inox', value: 'rap_inox' },
	{ id: 10, label: 'Hàn', value: 'han' },
	{ id: 11, label: 'Sơn', value: 'son' },
	{ id: 12, label: 'Ăn mòn', value: 'an_mon' },
	{ id: 13, label: 'Ráp tôn', value: 'rap_ton' },
	{ id: 14, label: 'Bao gói', value: 'bao_goi' },
	{ id: 15, label: 'Chờ xuất', value: 'cho_xuat' },
	{ id: 16, label: 'Xuất hàng', value: 'xuat_hang' },
];

export const FIREBASE_CONFIG = {
	apiKey: 'AIzaSyALTTuXy6xAEJfzbHatwkeozcnami5JKJo',
	authDomain: 'oee-app-v2.firebaseapp.com',
	projectId: 'oee-app-v2',
	storageBucket: 'oee-app-v2.appspot.com',
};
