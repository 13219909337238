import { Routes, Route, Navigate } from 'react-router-dom';
import './assets/scss/style.scss';
import { Login } from './pages/login';
import { Dashboard } from './pages/dashboard';

export const App = () => {
	return (
		<Routes>
			<Route path='/login' element={<Login />} />
			<Route
				path='*'
				element={
					<RequireAuth>
						<Dashboard />
					</RequireAuth>
				}
			/>
		</Routes>
	);
};

function RequireAuth({ children }: { children: JSX.Element }) {
	const token = localStorage.getItem('token');
	if (!token) {
		return <Navigate to='/login' replace />;
	}
	return children;
}
