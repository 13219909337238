export enum AllTypeActions {
	SYSTEM_MSG_HIDE = 'SYSTEM_MSG_HIDE',
	SYSTEM_MSG_ERROR = 'SYSTEM_MSG_ERROR',
	SYSTEM_MSG_SUCCESS = 'SYSTEM_MSG_SUCCESS',
	REQUEST_API = 'REQUEST_API',
	RESET_FULL = 'RESET_FULL',
	SHOW_HIDE_FILTER = 'SHOW_HIDE_FILTER',
	SERVER_ERROR = 'SERVER_ERROR',
}

export const setSuccessData = (key: string, data: any): any => ({
	type: `${AllTypeActions.REQUEST_API}_${key}_SUCCESS`,
	data,
});

export const showError = (message: string): any => ({
	type: AllTypeActions.SYSTEM_MSG_ERROR,
	message,
});

export const showSuccess = (message: string): any => ({
	type: AllTypeActions.SYSTEM_MSG_SUCCESS,
	message,
});

export const hideMessage = (): any => ({
	type: AllTypeActions.SYSTEM_MSG_HIDE,
});

export const resetStore = (key: string = ''): any => ({
	type: key ? `${AllTypeActions.REQUEST_API}_${key}_RESET` : AllTypeActions.RESET_FULL,
});

export const openFilter = (key: string): any => ({
	type: AllTypeActions.SHOW_HIDE_FILTER,
	key,
	status: true,
});

export const hideFilter = (key: string): any => ({
	type: AllTypeActions.SHOW_HIDE_FILTER,
	key,
	status: false,
});

export const serveRequest = (storekey: string, params?: any): any => ({
	type: AllTypeActions.REQUEST_API,
	storekey,
	params,
});
