export const initialRequestState: IAppRequest = {
	isFetching: false,
	data: null,
	error: '',
	pager: 0,
	hasMore: false,
	total: 0,
	summary: null,
	openFilter: false,
	filter: null,
};
export interface IAppRequest {
	isFetching: boolean;
	data: any;
	error: string;
	pager: number;
	hasMore: boolean;
	total: number;
	summary: any;
	openFilter: boolean;
	filter: any;
}

export type IRequest = IAppRequest | null | undefined;

export interface ISystemMsgState {
	isError: boolean;
	serverError: boolean;
	message: string;
}

export interface IApplicationState {
	sys: ISystemMsgState;
	stored: {
		login: IAppRequest;
		users: IAppRequest;
		orders: IAppRequest;
		me: IAppRequest;
		quotaGroups: IAppRequest;
		allQuotaGroups: IAppRequest;
		quotas: IAppRequest;
		reports: IAppRequest;
		dashboard: IAppRequest;
		order: IAppRequest;
	};
}
