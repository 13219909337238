import { useState, useEffect, useCallback } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import type { MenuProps } from 'antd';
import { Layout, Menu, message, Flex, Button, Tooltip, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
	UserOutlined,
	DatabaseOutlined,
	LogoutOutlined,
	SettingOutlined,
	MenuUnfoldOutlined,
	MenuFoldOutlined,
	FieldTimeOutlined,
	CalendarOutlined,
	BarChartOutlined,
} from '@ant-design/icons';
import { IApplicationState, serveRequest } from '../../store';
import { isMobile, usePrevious } from '../../helpers';
import { Orders } from '../orders';
import { Users } from '../users';
import { QuotaGroups } from '../quota-groups';
import { QuotaDetails } from '../quota-details';
import { SummaryReport } from './SummaryReports';
import { ListLoading } from '../../components/ListLoading';
import { OrderForm } from '../orders/OrderForm';

const { Header, Content, Footer, Sider } = Layout;

export const Dashboard = () => {
	const meStore = useSelector((state: IApplicationState) => state.stored.me);
	const me = meStore.data;
	const sys = useSelector((state: IApplicationState) => state.sys);
	const [collapsed, setCollapsed] = useState(window.innerWidth < 768 ? true : false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const lastSysErr = usePrevious(sys && sys.serverError);
	useEffect(() => {
		dispatch(serveRequest('me'));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if ((!lastSysErr && sys && sys.serverError) || (me && !me.is_active)) {
			if (sys && sys.message) message.error(sys.message);
			localStorage.removeItem('token');
			navigate('/login', { replace: true });
		}
	}, [lastSysErr, sys, navigate, me]);

	const logout = useCallback(() => {
		localStorage.removeItem('token');
		navigate('/login', { replace: true });
	}, [navigate]);

	const menus: MenuProps['items'] = [
		{
			key: `/`,
			icon: me && me.role_id === 'admin' ? <BarChartOutlined /> : <DatabaseOutlined />,
			label: me && me.role_id === 'admin' ? 'Báo cáo' : 'Đơn hàng',
			onClick: () => {
				if (isMobile()) setCollapsed(true);
				navigate('/');
			},
		},
	];

	if (meStore && meStore.isFetching) return <ListLoading />;

	if (me && me.role_id === 'admin') {
		menus.push({
			key: `/orders`,
			icon: <DatabaseOutlined />,
			label: 'Đơn hàng',
			onClick: () => {
				if (isMobile()) setCollapsed(true);
				navigate('/orders');
			},
		});
		menus.push({
			key: `/users`,
			icon: <UserOutlined />,
			label: 'Thành viên',
			onClick: () => {
				if (isMobile()) setCollapsed(true);
				navigate('/users');
			},
		});
		menus.push({
			key: `/configure`,
			icon: <SettingOutlined />,
			label: 'Cấu hình',
			children: [
				{
					key: `/configure-groups`,
					icon: <CalendarOutlined />,
					label: 'Gói định mức',
					onClick: () => {
						if (isMobile()) setCollapsed(true);
						navigate('/configure-groups');
					},
				},
				{
					key: `/configure-details`,
					icon: <FieldTimeOutlined />,
					label: 'Khâu định mức',
					onClick: () => {
						if (isMobile()) setCollapsed(true);
						navigate('/configure-details');
					},
				},
			],
		});
	}
	menus.push({
		key: `logout`,
		icon: <LogoutOutlined />,
		label: 'Đăng xuất',
		onClick: () => {
			logout();
		},
	});
	const defaultOpen = window.location.pathname.indexOf('/configure') !== -1 ? ['/configure'] : [];
	const menuSelected = window.location.pathname.indexOf('-order') !== -1 ? '/orders' : window.location.pathname;
	return (
		<Layout>
			<Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<Flex align='center' className='text-white'>
					<Button className='mobile-toggler' type='text' onClick={() => setCollapsed(!collapsed)} style={{ marginLeft: -40 }}>
						{collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
					</Button>
					<img
						style={{ marginRight: 10 }}
						src='https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg'
						height='32'
						width='32'
						alt='logo'
					/>
					{isMobile() ? <h1>QLSX</h1> : <h1>Quản lý sản xuất</h1>}
				</Flex>
				<Flex align='center' className='user-info text-white text-right'>
					<Typography.Title level={5} style={{ marginBottom: 0 }}>
						{me ? me.name : ''}
					</Typography.Title>
					<Tooltip title='Đăng xuất'>
						<LogoutOutlined style={{ marginLeft: 10, cursor: 'pointer', fontSize: 16 }} onClick={() => logout()} />
					</Tooltip>
				</Flex>
			</Header>
			<Layout style={{ width: isMobile() && !collapsed ? 'calc(100vw + 200px)' : 'auto' }}>
				<Sider width={250} collapsedWidth={isMobile() ? 0 : undefined} trigger={null} collapsible collapsed={collapsed}>
					<Menu mode='inline' defaultSelectedKeys={[menuSelected]} defaultOpenKeys={defaultOpen} style={{ height: '100%' }} items={menus} />
				</Sider>
				<Content style={{ padding: 20, minHeight: 800, background: '#FFF' }}>
					<Routes>
						<Route path='/' element={me && me.role_id === 'admin' ? <SummaryReport /> : <Orders />} />
						<Route path='/orders' element={<Orders />} />
						<Route
							path='/users'
							element={
								<RequireAdmin>
									<Users />
								</RequireAdmin>
							}
						/>
						<Route
							path='/configure-groups'
							element={
								<RequireAdmin>
									<QuotaGroups />
								</RequireAdmin>
							}
						/>
						<Route
							path='/configure-details'
							element={
								<RequireAdmin>
									<QuotaDetails />
								</RequireAdmin>
							}
						/>
						<Route
							path='/new-order'
							element={
								<RequireAdmin>
									<OrderForm />
								</RequireAdmin>
							}
						/>
						<Route
							path='/detail-order/:orderid'
							element={
								<RequireAdmin>
									<OrderForm />
								</RequireAdmin>
							}
						/>
					</Routes>
				</Content>
			</Layout>
			<Footer style={{ textAlign: 'center' }}>QLSX Admin ©{new Date().getFullYear()}</Footer>
		</Layout>
	);
};

const RequireAdmin = ({ children }: { children: JSX.Element }) => {
	const me = useSelector((state: IApplicationState) => state.stored.me.data);
	if (me && me.id && me.role_id !== 'admin') {
		message.error('Bạn không có quyền vào mục này!');
		return <Navigate to='/' replace />;
	}
	return children;
};
