import { put, takeEvery } from 'redux-saga/effects';
import { all, fork } from 'redux-saga/effects';
import { AllTypeActions, showError } from '../actions';
import { AnyAction } from 'redux';
import { Requests } from '../requests';

const netError = 'Có lỗi xảy ra. Vui lòng kiểm tra kế nối internet của bạn!';

function* requestApi({ type, params = {}, storekey }: AnyAction): Generator<any> {
	try {
		const page = params && params.page_index ? params.page_index : 1;
		yield put({ type: `${type}_${storekey}_ATTEMPT`, keepData: page > 1 });
		let data: any = yield Requests.request(storekey, params);
		if (data) {
			const obj = Object.keys(data);
			let reduxData: any = { ...data };
			let total = 0;
			if (obj.length === 1) {
				const firstObj: any = data[obj[0]];
				if (Array.isArray(firstObj)) {
					reduxData = [...firstObj];
				} else {
					reduxData = { ...firstObj };
					if (firstObj.total_item) total = Number(reduxData.total_item);
					if (firstObj.items && Array.isArray(firstObj.items)) {
						reduxData = [...firstObj.items];
					}
				}
			}
			yield put({
				type: `${type}_${storekey}_SUCCESS`,
				data: reduxData,
				pager: params && params.page_index ? page : 0,
				pageSize: params && params.page_size ? params.page_size : 0,
				total,
				summary: data.summary,
			});
			// if (process.env.NODE_ENV === 'development') console.log(data);
		} else {
			yield put({ type: `SERVER_ERROR`, error: netError });
			yield put({ type: `${type}_${storekey}_FAIL`, error: netError });
		}
	} catch (e) {
		if (process.env.NODE_ENV === 'development') console.log(e);
		const err: any = e;
		yield put({ type: `${type}_${storekey}_FAIL`, error: err.message ? err.message : 'unknown' });
		yield put({ type: `SERVER_ERROR`, error: err.message ? err.message : 'unknown' });
		yield put(showError(netError));
	}
}

export function* sagasActions() {
	yield takeEvery(AllTypeActions.REQUEST_API, requestApi);
}

export function* AllSagaActions() {
	yield all([fork(sagasActions)]);
}
