import { Button, Form, Input, Modal, Space, Table, Tooltip, Typography, Upload, message } from 'antd';
import { useCallback, useState } from 'react';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { fetchApi } from '../../store/requests';
import { serveRequest } from '../../store';
import { useDispatch } from 'react-redux';
import type { UploadProps, UploadFile } from 'antd';
import { DeleteOutlined, EditOutlined, PaperClipOutlined, PlusOutlined } from '@ant-design/icons';
import { FIREBASE_CONFIG } from '../../constants';
import { makeid } from '../../helpers';

export interface OrderAttachmentType {
	id: number;
	name: string;
	path: string;
}

interface Iprops {
	attachments: OrderAttachmentType[];
	orderid: number;
}

// Initialize Firebase
const app = initializeApp(FIREBASE_CONFIG);

// Firebase storage reference
const storage = getStorage(app);

export const OrderAttachments = (props: Iprops) => {
	const [edit, setEdit] = useState<OrderAttachmentType | null>(null);
	const [del, setDel] = useState<OrderAttachmentType | null>(null);
	const [add, setAdd] = useState<boolean>(false);
	const [uploadFiles, setUploadFiles] = useState<UploadFile[]>([]);
	const [saving, setSaving] = useState<boolean>(false);
	const [path, setPath] = useState('');
	const [formEdit] = Form.useForm();
	const [formAdd] = Form.useForm();
	const dispatch = useDispatch();
	const { orderid } = props;

	const reloadOrder = useCallback(() => {
		dispatch(serveRequest('order', { id: orderid }));
	}, [dispatch, orderid]);

	const handleEdit = useCallback(
		async (values: any) => {
			if (edit && path) {
				setSaving(true);
				try {
					const res = await fetchApi('editAttachment', {
						name: values.name,
						path: path,
						id: edit.id,
					});
					if (res) message.success('Cập nhật file đính kèm thành công!');
					reloadOrder();
				} catch (e) {
					message.error('Cập nhật file đính kèm không thành công! ' + String(e));
				}
				setSaving(false);
				setEdit(null);
				setUploadFiles([]);
				setPath('');
				formEdit.resetFields();
			}
		},
		[reloadOrder, edit, formEdit, path],
	);

	const handleAdd = useCallback(
		async (values: any) => {
			if (add && path) {
				setSaving(true);
				try {
					const res = await fetchApi('addAttachment', {
						name: values.name,
						path: path,
						order_id: orderid,
					});
					if (res) message.success('Tạo file đính kèm thành công!');
					reloadOrder();
				} catch (e) {
					message.error('Tạo file đính kèm không thành công! ' + String(e));
				}
				setSaving(false);
				setAdd(false);
				setUploadFiles([]);
				setPath('');
				formAdd.resetFields();
			}
		},
		[add, path, formAdd, orderid, reloadOrder],
	);

	const handleChange: UploadProps['onChange'] = useCallback(async ({ fileList: newFileList }: any) => {
		setUploadFiles(newFileList);
	}, []);

	const customUpload = useCallback(
		async ({ onError, onSuccess, file, onProgress }: any) => {
			const fileExt = file.name.split('.').pop();
			const storageRef = ref(storage, `/${orderid}/${makeid() + '.' + fileExt}`);
			if (add) formAdd.setFieldValue('name', file.name);
			if (edit) formEdit.setFieldValue('name', file.name);
			try {
				const uploadTask = uploadBytesResumable(storageRef, file);
				uploadTask.on(
					'state_changed',
					snapshot => {
						const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
						// update progress
						onProgress(percent);
					},
					err => onError(err),
					() => {
						// download url
						getDownloadURL(uploadTask.snapshot.ref).then(url => {
							setPath(url);
							console.log(url);
						});
						onSuccess(file);
					},
				);
			} catch (e) {
				console.log(e);
				onError(e);
			}
		},
		[add, edit, formAdd, formEdit, orderid],
	);

	const handleDel = useCallback(async () => {
		if (del) {
			setSaving(true);
			try {
				const res = await fetchApi('delAttachment', {
					id: del.id,
				});
				if (res) message.success('Xóa file đính kèm đơn thành công!');
				reloadOrder();
			} catch (e) {
				message.error('Xóa file đính kèm đơn không thành công! ' + String(e));
			}
			setSaving(false);
			setDel(null);
		}
	}, [reloadOrder, del]);

	return (
		<>
			<Space size='middle' style={{ marginBottom: 20 }}>
				<Typography.Title level={4} style={{ marginBottom: 0 }}>
					File đính kèm đơn
				</Typography.Title>
				<Button type='primary' icon={<PaperClipOutlined />} size='small' onClick={() => setAdd(true)}>
					Thêm file
				</Button>
			</Space>
			<Table
				columns={[
					{
						title: 'ID',
						dataIndex: 'id',
						key: 'id',
						defaultSortOrder: 'descend',
						sorter: (a, b) => Number(a.id) - Number(b.id),
					},
					{
						title: 'Tên',
						dataIndex: 'name',
						key: 'name',
					},
					{
						title: 'Link tải về',
						dataIndex: 'path',
						key: 'path',
						render: (text: string) =>
							text ? (
								<a href={text} target='_blank' rel='noopener noreferrer' download>
									<Button type='link' icon={<PaperClipOutlined />}>
										Download
									</Button>
								</a>
							) : null,
					},
					{
						title: 'Tác vụ',
						key: 'action',
						render: (_, record) => (
							<Space size='middle'>
								<Tooltip title='Sửa'>
									<Button
										type='default'
										icon={<EditOutlined />}
										size='small'
										onClick={() => {
											setEdit(record);
											setUploadFiles([{ name: record.name, status: 'done', uid: String(record.id), url: record.path }]);
										}}
									/>
								</Tooltip>
								<Tooltip title='Xóa'>
									<Button danger icon={<DeleteOutlined />} size='small' onClick={() => setDel(record)} />
								</Tooltip>
							</Space>
						),
					},
				]}
				rowKey={'id'}
				dataSource={props.attachments}
			/>
			<Modal width={640} footer={null} centered open={!!edit} onCancel={() => setEdit(null)}>
				{edit ? (
					<Form
						name={`edit-file`}
						form={formEdit}
						labelCol={{ span: 4 }}
						wrapperCol={{ span: 16 }}
						layout='horizontal'
						style={{ maxWidth: 700, padding: 20 }}
						initialValues={{
							id: Number(edit.id),
							name: edit.name,
							path: edit.path,
						}}
						onFinish={handleEdit}
						autoComplete='off'
					>
						<Form.Item label='Chọn file' name='file' rules={[{ required: true, message: 'File không được trống!' }]}>
							<Upload
								listType='picture-card'
								multiple={false}
								fileList={uploadFiles}
								onChange={handleChange}
								customRequest={customUpload}
								action={''}
							>
								{uploadFiles.length ? null : (
									<button style={{ border: 0, background: 'none' }} type='button'>
										<PlusOutlined />
										<div style={{ marginTop: 8 }}>Upload</div>
									</button>
								)}
							</Upload>
						</Form.Item>

						<Form.Item label='Tên file' name='name'>
							<Input disabled />
						</Form.Item>

						<Form.Item wrapperCol={{ offset: 4, span: 16 }}>
							<Button type='primary' htmlType='submit' loading={saving} disabled={saving}>
								Cập nhật
							</Button>
						</Form.Item>
					</Form>
				) : null}
			</Modal>
			<Modal
				width={640}
				footer={null}
				centered
				open={!!add}
				onCancel={() => {
					setAdd(false);
					formAdd.resetFields();
				}}
			>
				{add ? (
					<Form
						name={`add-file`}
						form={formAdd}
						labelCol={{ span: 4 }}
						wrapperCol={{ span: 16 }}
						layout='horizontal'
						style={{ maxWidth: 600, padding: 20 }}
						onFinish={handleAdd}
						autoComplete='off'
					>
						<Form.Item label='Chọn file' name='file' rules={[{ required: true, message: 'File không được trống!' }]}>
							<Upload
								listType='picture-card'
								multiple={false}
								fileList={uploadFiles}
								action={''}
								onChange={handleChange}
								customRequest={customUpload}
							>
								{uploadFiles.length ? null : (
									<button style={{ border: 0, background: 'none' }} type='button'>
										<PlusOutlined />
										<div style={{ marginTop: 8 }}>Upload</div>
									</button>
								)}
							</Upload>
						</Form.Item>

						<Form.Item label='Tên file' name='name'>
							<Input disabled />
						</Form.Item>

						<Form.Item wrapperCol={{ offset: 4, span: 16 }}>
							<Button type='primary' htmlType='submit' loading={saving} disabled={saving}>
								Lưu lại
							</Button>
						</Form.Item>
					</Form>
				) : null}
			</Modal>
			<Modal
				width={640}
				centered
				open={!!del}
				onCancel={() => setDel(null)}
				okButtonProps={{ loading: saving, disabled: saving }}
				okText='Có'
				cancelText='Không'
				onOk={handleDel}
			>
				<h4>Bạn có chắc chắn muốn xóa không ?</h4>
			</Modal>
		</>
	);
};
