import { AnyAction, Reducer } from 'redux';
import _ from 'lodash';
import { AllTypeActions } from '../actions';
import { ISystemMsgState, initialRequestState } from './state';
import { ENDPOINTS } from '../requests';

const defaultStored: any = {};
for (const storeKeys in ENDPOINTS) {
	defaultStored[storeKeys] = initialRequestState;
}

export const StoredReducer = (state: any, action: AnyAction): any => {
	const defaultState = state === undefined ? defaultStored : { ...state };
	if (String(action.type).indexOf(AllTypeActions.REQUEST_API) === 0) {
		const actions = `${String(action.type)}`.replace(`${AllTypeActions.REQUEST_API}_`, '').split('_');
		if (actions.length === 2) {
			const storekey = actions[0];
			const keystate = actions[1];
			const currentStored = storekey in defaultState ? defaultState[storekey] : { ...initialRequestState };
			if (keystate === 'ATTEMPT') {
				defaultState[storekey] = { ...currentStored, isFetching: true, error: '' };
				return defaultState;
			}
			if (keystate === 'FAIL') {
				defaultState[storekey] = { ...currentStored, error: action.error, isFetching: false, data: null };
				return defaultState;
			}
			if (keystate === 'RESET') {
				defaultState[storekey] = undefined;
				return defaultState;
			}
			if (keystate === 'SUCCESS') {
				const pager = action.pager ? action.pager : 0;
				const hasMore =
					action.pageSize && action.pager > 0 && Array.isArray(action.data) && action.data.length >= action.pageSize ? true : false;
				defaultState[storekey] = { ...currentStored, error: action.error, isFetching: false, data: null };
				if (Array.isArray(action.data) && action.pager > 1 && currentStored.data && currentStored.data.length) {
					const data = pager > currentStored.pager ? _.concat(currentStored.data, action.data) : currentStored.data;
					defaultState[storekey] = {
						...currentStored,
						data,
						pager,
						hasMore,
						isFetching: false,
						error: '',
						total: action.total ? Number(action.total) : 0,
						summary: action.summary ? { ...action.summary } : null,
					};
					return defaultState;
				}
				defaultState[storekey] = {
					...currentStored,
					data: action.data,
					pager,
					hasMore,
					error: '',
					isFetching: false,
					total: action.total ? Number(action.total) : 0,
					summary: action.summary ? { ...action.summary } : null,
				};
				return defaultState;
			}
		}
	}
	if (action.type === AllTypeActions.RESET_FULL) {
		return {};
	}
	if (action.type === AllTypeActions.SHOW_HIDE_FILTER) {
		const storekey = action.key;
		const currentStored = storekey in defaultState ? defaultState[storekey] : { ...initialRequestState };
		defaultState[storekey] = { ...currentStored, openFilter: action.status };
		return defaultState;
	}
	return defaultState;
};

export const SystemMessageReducer: Reducer = (state: ISystemMsgState, action: AnyAction) => {
	const defaultState = state === undefined ? { isError: false, serverError: false, message: '' } : { ...state };
	switch (action.type) {
		case AllTypeActions.SYSTEM_MSG_ERROR: {
			return {
				...defaultState,
				isError: true,
				message: action.message,
			};
		}
		case AllTypeActions.SERVER_ERROR: {
			return {
				...defaultState,
				serverError: true,
				message: action.message,
			};
		}
		case AllTypeActions.SYSTEM_MSG_SUCCESS: {
			return {
				...defaultState,
				isError: false,
				message: action.message,
			};
		}
		case AllTypeActions.SYSTEM_MSG_HIDE: {
			return {
				...defaultState,
				isError: false,
				message: '',
			};
		}
		default:
			return defaultState;
	}
};
