import { Button, Form, Input, InputNumber, Modal, Space, Table, Tooltip, Typography, message } from 'antd';
import { useCallback, useState } from 'react';
import { fetchApi } from '../../store/requests';
import { serveRequest } from '../../store';
import { useDispatch } from 'react-redux';
import { DeleteOutlined, EditOutlined, AppstoreAddOutlined } from '@ant-design/icons';

export interface OrderCustomFieldType {
	id: number;
	name: string;
	value: string;
}

interface Iprops {
	fields: OrderCustomFieldType[];
	orderid: number;
}

export const OrderFields = (props: Iprops) => {
	const [edit, setEdit] = useState<OrderCustomFieldType | null>(null);
	const [del, setDel] = useState<OrderCustomFieldType | null>(null);
	const [add, setAdd] = useState<boolean>(false);
	const [saving, setSaving] = useState<boolean>(false);
	const [formEdit] = Form.useForm();
	const [formAdd] = Form.useForm();
	const dispatch = useDispatch();
	const { orderid } = props;

	const reloadOrder = useCallback(() => {
		dispatch(serveRequest('order', { id: orderid }));
	}, [dispatch, orderid]);

	const handleEdit = useCallback(
		async (values: any) => {
			if (edit) {
				setSaving(true);
				try {
					const res = await fetchApi('editField', {
						...values,
					});
					if (res) message.success('Cập nhật thông tin đơn thành công!');
					reloadOrder();
				} catch (e) {
					message.error('Cập nhật thông tin đơn không thành công! ' + String(e));
				}
				setSaving(false);
				setEdit(null);
				formEdit.resetFields();
			}
		},
		[reloadOrder, edit, formEdit],
	);

	const handleAdd = useCallback(
		async (values: any) => {
			setSaving(true);
			try {
				const res = await fetchApi('addField', {
					...values,
					order_id: orderid,
				});
				if (res) message.success('Tạo thông tin đơn thành công!');
				reloadOrder();
			} catch (e) {
				message.error('Tạo thông tin đơn không thành công! ' + String(e));
			}
			setSaving(false);
			setAdd(false);
			formAdd.resetFields();
		},
		[reloadOrder, formAdd, orderid],
	);

	const handleDel = useCallback(async () => {
		if (del) {
			setSaving(true);
			try {
				const res = await fetchApi('delField', {
					id: del.id,
				});
				if (res) message.success('Xóa thông tin đơn thành công!');
				reloadOrder();
			} catch (e) {
				message.error('Xóa thông tin đơn không thành công! ' + String(e));
			}
			setSaving(false);
			setDel(null);
		}
	}, [reloadOrder, del]);

	return (
		<>
			<Space size='middle' style={{ marginBottom: 20 }}>
				<Typography.Title level={4} style={{ marginBottom: 0 }}>
					Các thông tin mở rộng
				</Typography.Title>
				<Button type='primary' icon={<AppstoreAddOutlined />} size='small' onClick={() => setAdd(true)}>
					Tạo trường
				</Button>
			</Space>
			<Table
				columns={[
					{
						title: 'ID',
						dataIndex: 'id',
						key: 'id',
						defaultSortOrder: 'descend',
						sorter: (a, b) => Number(a.id) - Number(b.id),
					},
					{
						title: 'Tên',
						dataIndex: 'name',
						key: 'name',
					},
					{
						title: 'Nội dung',
						dataIndex: 'value',
						key: 'value',
						render: (text: string) => (text.length > 20 ? text.substring(0, 20) + '...' : text),
					},
					{
						title: 'Tác vụ',
						key: 'action',
						render: (_, record) => (
							<Space size='middle'>
								<Tooltip title='Sửa'>
									<Button type='default' icon={<EditOutlined />} size='small' onClick={() => setEdit(record)} />
								</Tooltip>
								<Tooltip title='Xóa'>
									<Button danger icon={<DeleteOutlined />} size='small' onClick={() => setDel(record)} />
								</Tooltip>
							</Space>
						),
					},
				]}
				dataSource={props.fields}
				rowKey={'id'}
			/>
			<Modal width={640} footer={null} centered open={!!edit} onCancel={() => setEdit(null)}>
				{edit ? (
					<Form
						name={`edit-field`}
						form={formEdit}
						labelCol={{ span: 4 }}
						wrapperCol={{ span: 16 }}
						layout='horizontal'
						style={{ maxWidth: 700, padding: 20 }}
						initialValues={{
							id: Number(edit.id),
							name: edit.name,
							value: edit.value,
						}}
						onFinish={handleEdit}
						autoComplete='off'
					>
						<Form.Item label='ID' name='id'>
							<InputNumber disabled />
						</Form.Item>

						<Form.Item label='Tên trường' name='name' rules={[{ required: true, message: 'Tên không được trống!' }]}>
							<Input />
						</Form.Item>

						<Form.Item label='Nội dung' name='value' rules={[{ required: true, message: 'Nội dung không được trống!' }]}>
							<Input.TextArea style={{ minHeight: 300 }} />
						</Form.Item>

						<Form.Item wrapperCol={{ offset: 4, span: 16 }}>
							<Button type='primary' htmlType='submit' loading={saving} disabled={saving}>
								Cập nhật
							</Button>
						</Form.Item>
					</Form>
				) : null}
			</Modal>
			<Modal
				width={640}
				footer={null}
				centered
				open={!!add}
				onCancel={() => {
					setAdd(false);
					formAdd.resetFields();
				}}
			>
				{add ? (
					<Form
						name={`add-field`}
						form={formAdd}
						labelCol={{ span: 4 }}
						wrapperCol={{ span: 16 }}
						layout='horizontal'
						style={{ maxWidth: 600, padding: 20 }}
						onFinish={handleAdd}
						autoComplete='off'
					>
						<Form.Item label='Tên trường' name='name' rules={[{ required: true, message: 'Tên không được trống!' }]}>
							<Input />
						</Form.Item>

						<Form.Item label='Nội dung' name='value' rules={[{ required: true, message: 'Nội dung không được trống!' }]}>
							<Input.TextArea style={{ minHeight: 300 }} />
						</Form.Item>

						<Form.Item wrapperCol={{ offset: 4, span: 16 }}>
							<Button type='primary' htmlType='submit' loading={saving} disabled={saving}>
								Thêm trường
							</Button>
						</Form.Item>
					</Form>
				) : null}
			</Modal>
			<Modal
				width={640}
				centered
				open={!!del}
				onCancel={() => setDel(null)}
				okButtonProps={{ loading: saving, disabled: saving }}
				okText='Có'
				cancelText='Không'
				onOk={handleDel}
			>
				<h4>Bạn có chắc chắn muốn xóa không ?</h4>
			</Modal>
		</>
	);
};
