import { useState, useCallback, useEffect } from 'react';
import { Space, Table, Tag, Button, Modal, Form, Tooltip, Input, Select, Flex, List, message, Switch } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useSelector, useDispatch } from 'react-redux';
import { IApplicationState, serveRequest } from '../../store';
import { PAGE_SIZE, fetchApi } from '../../store/requests';
import { EditOutlined } from '@ant-design/icons';
import { ListLoading } from '../../components/ListLoading';
import { ROLES } from '../../constants';

interface UserType {
	id: number;
	name: string;
	email: string;
	is_active: boolean;
	role_id: string;
}

export const Users = () => {
	const users = useSelector((state: IApplicationState) => state.stored.users);
	const [editUser, setEditUser] = useState<UserType | null>(null);
	const [addUser, setAddUser] = useState(false);
	const [page_size, setPageSize] = useState<number>(PAGE_SIZE);
	const [page_index, setPageIndex] = useState<number>(1);
	const [keyword, setKeyword] = useState<string>('');
	const [saving, setSaving] = useState<boolean>(false);
	const dispatch = useDispatch();
	const [formEdit] = Form.useForm();
	const [formSearch] = Form.useForm();
	const [formAdd] = Form.useForm();

	useEffect(() => {
		dispatch(serveRequest('users', { page_index: 1, page_size: PAGE_SIZE }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (formEdit && editUser)
			formEdit.setFieldsValue({
				name: editUser.name,
				email: editUser.email,
				role_id: editUser.role_id,
				is_active: editUser.is_active ? true : false,
			});
	}, [formEdit, editUser]);

	useEffect(() => {
		if (formSearch)
			formSearch.setFieldsValue({
				keyword: keyword,
			});
	}, [formSearch, keyword]);

	const handleLoadList = useCallback(
		(pager?: number) => {
			if (users && !users.isFetching) {
				if (pager) setPageIndex(pager);
				dispatch(serveRequest('users', { page_index: pager ? pager : page_index, page_size }));
			}
		},
		[dispatch, page_size, users, page_index],
	);

	const handleEditUser = useCallback(
		async (values: any) => {
			if (editUser) {
				setSaving(true);
				try {
					const res = await fetchApi('editUser', {
						...values,
						id: editUser.id,
					});
					if (res) message.success('Cập nhật thành viên thành công!');
					handleLoadList();
				} catch (e) {
					message.error('Cập nhật thành viên không thành công! ' + String(e));
				}
				setSaving(false);
				setEditUser(null);
				formEdit.resetFields();
			}
		},
		[editUser, formEdit, handleLoadList],
	);

	const handleAddUser = useCallback(
		async (values: any) => {
			setSaving(true);
			try {
				const res = await fetchApi('addUser', {
					...values,
				});
				if (res) message.success('Tạo thành viên thành công!');
				handleLoadList(1);
			} catch (e) {
				message.error('Tạo thành viên không thành công! ' + String(e));
			}
			setSaving(false);
			setAddUser(false);
			formAdd.resetFields();
		},
		[formAdd, handleLoadList],
	);

	const handleSearch = useCallback(
		(values: any) => {
			if (values.keyword) {
				setKeyword(values.keyword);
				dispatch(serveRequest('users', { page_index: 1, page_size, name: values.keyword }));
			} else if (keyword) {
				handleLoadList(1);
				setKeyword('');
			}
		},
		[dispatch, page_size, handleLoadList, keyword],
	);

	if (users && users.isFetching) return <ListLoading />;

	const data = users && users.data ? users.data : [];
	const columns: ColumnsType<UserType> = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			defaultSortOrder: 'descend',
			sorter: (a, b) => Number(a.id) - Number(b.id),
			className: 'hide-mobile',
		},
		{
			title: 'Tên',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			className: 'hide-mobile',
		},
		{
			title: 'Trạng thái',
			dataIndex: 'is_active',
			key: 'is_active',
			render: text => (Number(text) === 1 ? <Tag color={'green'}>Active</Tag> : <Tag color={'red'}>Inactive</Tag>),
			defaultSortOrder: 'descend',
			sorter: (a, b) => Number(a.is_active) - Number(b.is_active),
			className: 'hide-mobile',
		},
		{
			title: 'Vai trò',
			dataIndex: 'role_id',
			key: 'role_id',
			render: text => {
				const role = ROLES.find(r => r.value === text);
				return role ? role.label : text;
			},
			className: 'hide-mobile',
		},
		{
			title: 'Tác vụ',
			key: 'action',
			render: (_, record) => (
				<Space size='middle'>
					<Tooltip title='Cập nhật'>
						<Button type='default' icon={<EditOutlined />} size='small' onClick={() => setEditUser(record)} />
					</Tooltip>
				</Space>
			),
		},
	];
	return (
		<>
			<Form form={formSearch} name='search' onFinish={handleSearch} autoComplete='off'>
				<Flex style={{ flexWrap: 'wrap', marginBottom: 20 }}>
					<Form.Item name='keyword' style={{ marginBottom: 10 }}>
						<Input placeholder='Tìm theo tên' style={{ minWidth: 200 }} />
					</Form.Item>
					<Button type='default' htmlType='submit' style={{ marginLeft: 10, marginRight: 10 }}>
						Tìm
					</Button>
					<Button
						type='primary'
						htmlType='button'
						onClick={() => {
							setAddUser(true);
							formAdd.resetFields();
						}}
					>
						Tạo tài khoản
					</Button>
				</Flex>
			</Form>
			<Table
				rowKey='id'
				columns={columns}
				dataSource={data}
				pagination={{
					total: users && users.total ? users.total : data.length,
					current: page_index,
					pageSize: page_size,
					onChange: page => {
						handleLoadList(page);
					},
					onShowSizeChange: (current, size) => {
						setPageSize(size);
					},
				}}
				expandable={{
					expandedRowRender: record => (
						<List>
							<List.Item>
								<b>Email:</b> {record.email}
							</List.Item>
							<List.Item>
								<b>Trạng thái:</b>{' '}
								{Number(record.is_active) === 1 ? <Tag color={'green'}>Active</Tag> : <Tag color={'red'}>Inactive</Tag>}
							</List.Item>
							<List.Item>
								<b>Vai trò:</b> {ROLES.find(r => r.value === record.role_id)?.label}
							</List.Item>
						</List>
					),
				}}
			/>
			<Modal width={640} footer={null} centered open={!!editUser} onCancel={() => setEditUser(null)}>
				{editUser ? (
					<Form
						form={formEdit}
						name='edit-user'
						labelCol={{ span: 4 }}
						wrapperCol={{ span: 14 }}
						layout='horizontal'
						style={{ maxWidth: 600, padding: 20 }}
						onFinish={handleEditUser}
						autoComplete='off'
					>
						<Form.Item label='Tên' name='name' rules={[{ required: true, message: 'Tên không được trống!' }]}>
							<Input />
						</Form.Item>

						<Form.Item label='Email' name='email' rules={[{ required: true, message: 'Email không được trống!' }]}>
							<Input />
						</Form.Item>

						<Form.Item label='Bộ phận' name='role_id' rules={[{ required: true, message: 'Thành viên bộ phận là bắt buộc!' }]}>
							<Select style={{ width: '100%' }} options={ROLES} />
						</Form.Item>

						<Form.Item label='Hoạt động' name='is_active'>
							<Switch />
						</Form.Item>

						<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
							<Button type='primary' htmlType='submit' loading={saving} disabled={saving}>
								Cập nhật
							</Button>
						</Form.Item>
					</Form>
				) : null}
			</Modal>
			<Modal width={640} footer={null} centered open={addUser} onCancel={() => setAddUser(false)}>
				<Form
					form={formAdd}
					name='add-user'
					labelCol={{ span: 4 }}
					wrapperCol={{ span: 14 }}
					layout='horizontal'
					style={{ maxWidth: 600, padding: 20 }}
					initialValues={{ is_active: true }}
					onFinish={handleAddUser}
					autoComplete='off'
				>
					<Form.Item label='Tên' name='name' rules={[{ required: true, message: 'Tên không được trống!' }]}>
						<Input />
					</Form.Item>

					<Form.Item label='Email' name='email' rules={[{ required: true, message: 'Email không được trống!' }]}>
						<Input />
					</Form.Item>

					<Form.Item label='Bộ phận' name='role_id' rules={[{ required: true, message: 'Thành viên bộ phận là bắt buộc!' }]}>
						<Select style={{ width: '100%' }} options={ROLES} />
					</Form.Item>

					<Form.Item label='Hoạt động' name='is_active'>
						<Switch />
					</Form.Item>

					<Form.Item label='Password' name='password' rules={[{ required: true, message: 'Mật khẩu không được trống!' }]}>
						<Input.Password />
					</Form.Item>

					<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
						<Button type='primary' htmlType='submit' loading={saving} disabled={saving}>
							Tạo tài khoản
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};
