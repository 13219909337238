import axios from 'axios';
import { isNumeric } from '../../helpers';

export const PAGE_SIZE = 10;

export const ENDPOINTS: any = {
	login: {
		gql: 'mutation($email: String, $password: String){login(email: $email, password: $password) {id, email, password, name, access_token, is_active}}',
		method: 'post',
	},
	users: {
		gql: `{ users($params){total_item items {id name is_active email role_id}}}`,
		method: 'get',
		accept_params: ['page_size', 'page_index', 'name'],
	},
	addUser: {
		gql: 'mutation($name: String, $email: String, $password: String, $role_id: RoleEnum){addUser(email: $email, name: $name, password: $password, role_id: $role_id) {id, name, email}}',
		method: 'post',
	},
	editUser: {
		gql: 'mutation($id: ID, $email: String, $name: String, $role_id: RoleEnum, $is_active: Int){updateUser(id: $id, email: $email, name: $name, role_id: $role_id, is_active: $is_active) {id, email, name, role_id}}',
		method: 'post',
	},
	orders: {
		gql: `{ orders($params){total_item items {id description status  status_a status_b warning_a warning_b order_code start_date end_date created_date modified_date}}}`,
		method: 'get',
		accept_params: ['page_size', 'page_index', 'order_code'],
	},
	editOrder: {
		gql: 'mutation($id: ID, $status_a: OrderStatusEnum, $status_b: OrderStatusEnum, $description: String, $configuration_id: Int){updateOrder(id: $id, status_a: $status_a, status_b: $status_b, description: $description, configuration_id: $configuration_id) {id, order_code, status}}',
		method: 'post',
	},
	addOrder: {
		gql: 'mutation($order_code: String, $status_a: OrderStatusEnum, $status_b: OrderStatusEnum, $description: String, $start_date: String, $end_date: String, $configuration_id: Int){addOrder(order_code: $order_code, status_a: $status_a, status_b: $status_b, description: $description, start_date: $start_date, end_date: $end_date, configuration_id: $configuration_id) {id, order_code, status}}',
		method: 'post',
	},
	nextOrder: { gql: 'mutation($id: ID, $action: String){nextOrder(id: $id, action: $action) {id, order_code, status}}', method: 'post' },
	me: { gql: `{ me{id, email, name, is_active, role_id}}`, method: 'get' },
	allQuotaGroups: {
		gql: `{ configurations($params){total_item items {id name enabled}}}`,
		method: 'get',
		accept_params: ['page_size', 'page_index'],
	},
	quotaGroups: { gql: `{ configurations($params){total_item items {id name enabled}}}`, method: 'get', accept_params: ['page_size', 'page_index'] },
	editQuotaGroup: {
		gql: `mutation($id: ID, $name: String, $enabled: Boolean){updateConfiguration(id: $id, name: $name, enabled: $enabled) {id, name, enabled}}`,
		method: 'post',
	},
	addQuotaGroup: {
		gql: `mutation($name: String, $enabled: Boolean){addConfiguration(name: $name, enabled: $enabled) {id, name, enabled}}`,
		method: 'post',
	},
	delQuotaGroup: { gql: `mutation($id: ID){removeConfiguration(id: $id) {id}}`, method: 'post' },
	quotas: {
		gql: `{ configurationDetails($params){total_item items {id configuration_id order_status time_limit}}}`,
		method: 'get',
		accept_params: ['page_size', 'page_index'],
	},
	editQuota: {
		gql: `mutation($id: ID, $configuration_id: Int, $order_status: OrderStatusEnum, $time_limit: Int){updateConfigurationDetail(id: $id, configuration_id: $configuration_id, order_status: $order_status, time_limit: $time_limit) {id, configuration_id, order_status, time_limit}}`,
		method: 'post',
	},
	addQuota: {
		gql: `mutation($configuration_id: Int, $order_status: OrderStatusEnum, $time_limit: Int){addConfigurationDetail(configuration_id: $configuration_id, order_status: $order_status, time_limit: $time_limit) {id, configuration_id, order_status, time_limit}}`,
		method: 'post',
	},
	delQuota: { gql: `mutation($id: ID){removeConfigurationDetail(id: $id) {id}}`, method: 'post' },
	dashboard: { gql: `{ dashboardReport{tong_don chua_hoan_thanh dung_tien_do canh_bao cham_tien_do }}`, method: 'get' },
	reports: { gql: `{ summaryReport{trang_thai tong_don dung_tien_do canh_bao cham_tien_do }}`, method: 'get' },
	order: {
		gql: `{ order($params){ id configuration_id order_code description status status_a status_b warning_a warning_b start_date end_date created_date modified_date fields {id name value}  attachments {id name path}}}`,
		method: 'get',
		accept_params: ['id'],
	},
	editField: {
		gql: `mutation($id: ID, $name: String, $value: String){updateOrderField(id: $id, name: $name, value: $value) {id, name, value}}`,
		method: 'post',
	},
	addField: {
		gql: `mutation($order_id:Int, $name: String, $value: String){addOrderField(order_id: $order_id, name: $name, value: $value) {id, name, value order_id}}`,
		method: 'post',
	},
	delField: { gql: `mutation($id: ID){removeOrderField(id: $id) {id}}`, method: 'post' },
	editAttachment: {
		gql: `mutation($id: ID, $name: String, $path: String){updateOrderAttachment(id: $id, name: $name, path: $path) {id, name, path}}`,
		method: 'post',
	},
	addAttachment: {
		gql: `mutation($order_id:Int, $name: String, $path: String){addOrderAttachment(order_id: $order_id, name: $name, path: $path) {id, name, path order_id}}`,
		method: 'post',
	},
	delAttachment: { gql: `mutation($id: ID){removeOrderAttachment(id: $id) {id}}`, method: 'post' },
};

export const getGraphQLQuery = (key: string): string => {
	let gql = '';
	if (key in ENDPOINTS && ENDPOINTS[key].gql) {
		gql = ENDPOINTS[key].gql;
	}
	return gql;
};

const defaultHeader = (token: string | null) => {
	const header: any = {
		'Content-Type': 'application/json',
		'Cache-Control': 'no-cache',
	};
	if (token) header.Authorization = `Bearer ${token}`;
	return header;
};

class ApiRequests {
	public request(key: string, params: any = {}): Promise<any> {
		const token = localStorage.getItem('token');
		if (key in ENDPOINTS) {
			let gql = getGraphQLQuery(key);
			let apiUrl = window.location.origin + '/serve/';
			const data: any = {};
			const method = ENDPOINTS[key].method;
			const headers = defaultHeader(token);
			if (process.env.NODE_ENV === 'development') console.log('API ' + method.toUpperCase() + ' ----> ', gql);
			if (method === 'get') {
				const accept_params = ENDPOINTS[key].accept_params;
				if (accept_params && accept_params.length) {
					const replaceParams = [];
					for (const variableKey of accept_params) {
						if (params[variableKey] !== undefined) {
							const value =
								['page_size', 'page_index', 'id'].indexOf(variableKey) !== -1 && isNumeric(params[variableKey])
									? Number(params[variableKey])
									: '"' + String(params[variableKey]).replace('"', '"') + '"';
							replaceParams.push(variableKey + ':' + value);
						}
					}
					const replaceText = replaceParams.length ? replaceParams.join(',') : '';
					gql = gql.replace('$params', replaceText);
				}
				apiUrl = apiUrl + '?query=' + gql;
				return new Promise<any>((resolve, reject) => {
					axios
						.get(apiUrl, { headers })
						.then((response: any) => {
							const jsonData = response.data;
							if (jsonData.success) {
								resolve(jsonData && jsonData.data ? jsonData.data : jsonData);
							} else {
								reject('Server chưa sẵn sàng! Hãy thử lại sau.');
							}
						})
						.catch(e => {
							reject(e);
						});
				});
			} else {
				data.query = gql;
				data.variables = params;
				return new Promise<any>((resolve, reject) => {
					axios
						.post(apiUrl, data, { headers })
						.then(response => {
							const jsonData = response.data;
							if (jsonData.success) {
								resolve(jsonData && jsonData.data ? jsonData.data : jsonData);
							} else {
								let err = 'Server chưa sẵn sàng! Hãy thử lại sau.';
								if (jsonData.errors && jsonData.errors.length) {
									err = jsonData.errors.map((e: any) => e.message).join('. ');
								}
								reject(err);
							}
						})
						.catch(e => {
							reject(e);
						});
				});
			}
		} else {
			return new Promise<any>((resolve, reject) => {
				reject('Endpoint not found!');
			});
		}
	}
}

export const Requests = new ApiRequests();

export const fetchApi = async (key: string, params: any = {}) => {
	return await Requests.request(key, params);
};
