import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, message, Button, Form, Input, Typography } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { isMediumDevice } from '../../helpers';
import { fetchApi } from '../../store/requests';

type FieldType = {
	email?: string;
	password?: string;
	remember?: string;
};

export const Login = React.memo(() => {
	const [error, setError] = React.useState<string>('');
	const navigate = useNavigate();

	const handleSubmit = React.useCallback(
		async (values: any) => {
			if (!error) {
				try {
					const res = await fetchApi('login', values);
					if (res && res.login) {
						localStorage.setItem('token', res.login.access_token);
						navigate('/', { replace: true });
						message.success('Đăng nhập thành công!');
					} else {
						message.error('Đăng nhập không thành công!');
					}
				} catch (e) {
					message.error('Đăng nhập không thành công! ' + String(e));
				}
			}
		},
		[error, navigate],
	);

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				height: '100vh',
				overflow: 'auto',
				backgroundImage: "url('https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/V-_oS6r-i7wAAAAAAAAAAAAAFl94AQBr')",
				backgroundSize: '100% 100%',
			}}
		>
			<Form
				name='normal_login'
				className='login-form'
				style={{ minWidth: 310, margin: '0 auto' }}
				initialValues={{ remember: true }}
				onFinish={handleSubmit}
				onFinishFailed={(err: any) => {
					if (err.errorFields && err.errorFields.length) setError(err.errorFields[0].errors[0]);
				}}
				onFieldsChange={() => setError('')}
				autoComplete='off'
			>
				<Typography.Title level={2} style={{ marginBottom: 0, textAlign: 'center', padding: isMediumDevice() ? '70px 0' : '100px 0' }}>
					Quản lý sản xuất
				</Typography.Title>
				{error && (
					<Alert
						style={{
							marginBottom: 24,
						}}
						message={error}
						type='error'
						showIcon
					/>
				)}

				<Form.Item<FieldType> name='email' rules={[{ required: true, message: 'Tài khoản không được trống!' }]}>
					<Input prefix={<UserOutlined className='site-form-item-icon' />} placeholder='Tên tài khoản hoặc Email' />
				</Form.Item>

				<Form.Item<FieldType> name='password' rules={[{ required: true, message: 'Mật khẩu không được trống!' }]}>
					<Input.Password prefix={<LockOutlined className='site-form-item-icon' />} placeholder='Mật khẩu' />
				</Form.Item>

				<Form.Item>
					<Button style={{ width: '100%' }} type='primary' htmlType='submit' className='login-form-button'>
						Đăng nhập
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
});
