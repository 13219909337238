import { useState, useCallback, useEffect } from 'react';
import { Space, Table, Button, Modal, Tooltip, Form, message, Flex, Select, InputNumber } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useSelector, useDispatch } from 'react-redux';
import { IApplicationState, serveRequest } from '../../store';
import { PAGE_SIZE, fetchApi } from '../../store/requests';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ListLoading } from '../../components/ListLoading';
import { ORDER_STATUS_A, ORDER_STATUS_B } from '../../constants';

interface QuotaDataType {
	id: string;
	configuration_id: string;
	order_status: string;
	time_limit: number;
}

export const QuotaDetails = () => {
	const items = useSelector((state: IApplicationState) => state.stored.quotas);
	const quotaGroups = useSelector((state: IApplicationState) => state.stored.allQuotaGroups);
	const [edit, setEdit] = useState<QuotaDataType | null>(null);
	const [del, setDel] = useState<QuotaDataType | null>(null);
	const [add, setAdd] = useState<boolean>(false);
	const [saving, setSaving] = useState<boolean>(false);
	const [page_size, setPageSize] = useState<number>(PAGE_SIZE);
	const [page_index, setPageIndex] = useState<number>(1);
	const dispatch = useDispatch();
	const [formEdit] = Form.useForm();
	const [formAdd] = Form.useForm();
	useEffect(() => {
		dispatch(serveRequest('quotas', { page_index: 1, page_size: PAGE_SIZE }));
		dispatch(serveRequest('allQuotaGroups', { page_index: 1, page_size: 100000 }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (formEdit && edit)
			formEdit.setFieldsValue({
				configuration_id: Number(edit.configuration_id),
				order_status: edit.order_status,
				time_limit: edit.time_limit,
			});
	}, [formEdit, edit]);

	const handleLoadList = useCallback(
		(pager?: number) => {
			if (items && !items.isFetching) {
				if (pager) setPageIndex(pager);
				dispatch(serveRequest('quotas', { page_index: pager ? pager : page_index, page_size }));
			}
		},
		[dispatch, page_size, items, page_index],
	);

	const handleEdit = useCallback(
		async (values: any) => {
			if (edit) {
				setSaving(true);
				try {
					const res = await fetchApi('editQuota', {
						...values,
						id: edit.id,
					});
					if (res) message.success('Cập nhật định mức thành công!');
					handleLoadList();
				} catch (e) {
					message.error('Cập nhật định mức không thành công! ' + String(e));
				}
				setSaving(false);
				setEdit(null);
				formEdit.resetFields();
			}
		},
		[handleLoadList, edit, formEdit],
	);

	const handleAdd = useCallback(
		async (values: any) => {
			setSaving(true);
			try {
				const res = await fetchApi('addQuota', {
					...values,
				});
				if (res) message.success('Tạo định mức thành công!');
				handleLoadList(1);
			} catch (e) {
				message.error('Tạo định mức không thành công! ' + String(e));
			}
			setSaving(false);
			setAdd(false);
			formAdd.resetFields();
		},
		[handleLoadList, formAdd],
	);

	const handleDel = useCallback(async () => {
		if (del) {
			setSaving(true);
			try {
				const res = await fetchApi('delQuota', {
					id: del.id,
				});
				if (res) message.success('Xóa định mức thành công!');
				handleLoadList(1);
			} catch (e) {
				message.error('Xóa định mức không thành công! ' + String(e));
			}
			setSaving(false);
			setDel(null);
		}
	}, [handleLoadList, del]);

	if (items && items.isFetching) return <ListLoading />;

	const teams = [...ORDER_STATUS_A, ...ORDER_STATUS_B];
	const parents =
		quotaGroups && quotaGroups.data && quotaGroups.data.length
			? quotaGroups.data.map((g: any) => {
					return { value: Number(g.id), label: g.name };
			  })
			: [];
	const data = items && items.data ? items.data : [];
	const columns: ColumnsType<QuotaDataType> = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			defaultSortOrder: 'descend',
			sorter: (a, b) => Number(a.id) - Number(b.id),
			className: 'hide-mobile',
		},
		{
			title: 'Bộ phận',
			dataIndex: 'order_status',
			key: 'order_status',
			render: text => {
				const team = teams.find((t: any) => t.value === text);
				return team ? team.label : text;
			},
		},
		{
			title: 'Gói cấu hình',
			dataIndex: 'configuration_id',
			key: 'configuration_id',
			render: text => {
				const parent = parents.find((p: any) => Number(p.value) === Number(text));
				return parent ? parent.label : text;
			},
		},
		{
			title: 'Thời gian (ngày)',
			dataIndex: 'time_limit',
			key: 'time_limit',
		},
		{
			title: 'Tác vụ',
			key: 'action',
			render: (_, record) => (
				<Space size='middle'>
					<Tooltip title='Sửa cấu hình'>
						<Button type='default' icon={<EditOutlined />} size='small' onClick={() => setEdit(record)} />
					</Tooltip>
					<Tooltip title='Xóa'>
						<Button danger icon={<DeleteOutlined />} size='small' onClick={() => setDel(record)} />
					</Tooltip>
				</Space>
			),
		},
	];

	return (
		<>
			<Flex style={{ marginBottom: 20 }}>
				<Button
					type='primary'
					htmlType='button'
					onClick={() => {
						setAdd(true);
						formAdd.setFieldsValue({ time_limit: 1 });
					}}
				>
					Tạo mới
				</Button>
			</Flex>
			<Table
				rowKey='id'
				columns={columns}
				dataSource={data}
				pagination={{
					total: items && items.total ? items.total : data.length,
					current: page_index,
					pageSize: page_size,
					onChange: page => {
						handleLoadList(page);
					},
					onShowSizeChange: (current, size) => {
						setPageSize(size);
					},
				}}
			/>
			<Modal width={640} footer={null} centered open={!!edit} onCancel={() => setEdit(null)}>
				{edit ? (
					<Form
						name={`edit-group`}
						form={formEdit}
						labelCol={{ span: 4 }}
						wrapperCol={{ span: 14 }}
						layout='horizontal'
						style={{ maxWidth: 600, padding: 20 }}
						initialValues={{
							configuration_id: Number(edit.configuration_id),
							order_status: edit.order_status,
							time_limit: edit.time_limit,
						}}
						onFinish={handleEdit}
						autoComplete='off'
					>
						<Form.Item
							label='Gói định mức'
							name='configuration_id'
							rules={[{ required: true, message: 'Gói định mức không được trống!' }]}
						>
							<Select style={{ width: '100%' }} options={parents} />
						</Form.Item>

						<Form.Item label='Bộ phận' name='order_status' rules={[{ required: true, message: 'Bộ phận không được trống!' }]}>
							<Select style={{ width: '100%' }} options={teams} />
						</Form.Item>

						<Form.Item label='Thời gian (ngày)' name='time_limit' rules={[{ required: true, message: 'Thời gian không được trống!' }]}>
							<InputNumber min={1} max={10} formatter={value => `${value}`.replace('.', '').replace(',', '')} />
						</Form.Item>

						<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
							<Button type='primary' htmlType='submit' loading={saving} disabled={saving}>
								Cập nhật
							</Button>
						</Form.Item>
					</Form>
				) : null}
			</Modal>
			<Modal
				width={640}
				footer={null}
				centered
				open={!!add}
				onCancel={() => {
					setAdd(false);
					formAdd.resetFields();
				}}
			>
				{add ? (
					<Form
						name={`add-groups`}
						form={formAdd}
						labelCol={{ span: 6 }}
						wrapperCol={{ span: 14 }}
						layout='horizontal'
						style={{ maxWidth: 600, padding: 20 }}
						onFinish={handleAdd}
						autoComplete='off'
					>
						<Form.Item
							label='Gói định mức'
							name='configuration_id'
							rules={[{ required: true, message: 'Gói định mức không được trống!' }]}
						>
							<Select style={{ width: '100%' }} options={parents} />
						</Form.Item>

						<Form.Item label='Bộ phận' name='order_status' rules={[{ required: true, message: 'Bộ phận không được trống!' }]}>
							<Select style={{ width: '100%' }} options={teams} />
						</Form.Item>

						<Form.Item
							label='Thời gian (ngày)'
							name='time_limit'
							rules={[
								{ required: true, message: 'Thời gian không được trống!' },
								{ type: 'number', min: 1, message: 'Thời gian tối thiểu 1 ngày' },
							]}
						>
							<InputNumber min={1} max={10} formatter={value => `${value}`.replace('.', '').replace(',', '')} />
						</Form.Item>

						<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
							<Button type='primary' htmlType='submit' loading={saving} disabled={saving}>
								Tạo định mức
							</Button>
						</Form.Item>
					</Form>
				) : null}
			</Modal>
			<Modal
				width={640}
				centered
				open={!!del}
				onCancel={() => setDel(null)}
				okButtonProps={{ loading: saving, disabled: saving }}
				okText='Có'
				cancelText='Không'
				onOk={handleDel}
			>
				<h4>Bạn có chắc chắn muốn xóa không ?</h4>
			</Modal>
		</>
	);
};
