import { useEffect } from 'react';
import { IApplicationState, serveRequest } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { ListLoading } from '../../components/ListLoading';
import { Card, Flex, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { ORDER_STATUS_A, ORDER_STATUS_B } from '../../constants';

interface ReportDataType {
	trang_thai: string;
	tong_don: number;
	dung_tien_do: number;
	cham_tien_do: number;
	canh_bao: number;
}

export const SummaryReport = () => {
	const dashboard = useSelector((state: IApplicationState) => state.stored.dashboard);
	const reports = useSelector((state: IApplicationState) => state.stored.reports);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(serveRequest('dashboard'));
		dispatch(serveRequest('reports'));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if ((dashboard && dashboard.isFetching) || (reports && reports.isFetching)) return <ListLoading />;

	const dataReports = reports && reports.data ? reports.data : [];
	const dataDashboard = dashboard && dashboard.data ? dashboard.data : null;
	const titles: any = {
		tong_don: 'Tổng đơn',
		chua_hoan_thanh: 'Chưa hoàn thành',
		dung_tien_do: 'Đúng tiến độ',
		canh_bao: 'Cảnh báo',
		cham_tien_do: 'Chậm tiến độ',
	};

	const columns: ColumnsType<ReportDataType> = [
		{
			title: 'Bộ phận',
			dataIndex: 'trang_thai',
			key: 'trang_thai',
			render: text => {
				const team = [...ORDER_STATUS_A, ...ORDER_STATUS_B].find(r => r.value === text);
				return team ? team.label : text;
			},
		},
		{
			title: 'Tổng đơn',
			dataIndex: 'tong_don',
			key: 'tong_don',
			defaultSortOrder: 'descend',
			sorter: (a, b) => Number(a.tong_don) - Number(b.tong_don),
		},
		{
			title: 'Đúng tiến độ',
			dataIndex: 'dung_tien_do',
			key: 'dung_tien_do',
			defaultSortOrder: 'descend',
			sorter: (a, b) => Number(a.dung_tien_do) - Number(b.dung_tien_do),
		},
		{
			title: 'Chậm tiến độ',
			dataIndex: 'cham_tien_do',
			key: 'cham_tien_do',
			defaultSortOrder: 'descend',
			sorter: (a, b) => Number(a.cham_tien_do) - Number(b.cham_tien_do),
		},
	];

	return (
		<>
			{dataDashboard ? (
				<Flex style={{ flexWrap: 'wrap', marginBottom: 20 }}>
					{Object.keys(dataDashboard).map((key: string) => {
						let color = '#b7eb8f';
						let bgColor = '#f6ffed';
						if (key === 'chua_hoan_thanh' || key === 'cham_tien_do') {
							color = '#ffccc7';
							bgColor = '#fff2f0';
						}
						if (key === 'canh_bao') {
							color = '#ffe58f';
							bgColor = '#fffbe6';
						}
						if (key === 'tong_don') {
							color = '#91caff';
							bgColor = '#e6f4ff';
						}
						return (
							<Card
								style={{ marginRight: 20, marginBottom: 20, minWidth: 250, borderColor: color }}
								headStyle={{ borderColor: color, backgroundColor: bgColor }}
								color='red'
								title={titles[key]}
								key={key}
							>
								<h2>{dataDashboard[key]}</h2>
							</Card>
						);
					})}
				</Flex>
			) : null}
			<Table rowKey='trang_thai' columns={columns} dataSource={dataReports} pagination={false} />
		</>
	);
};
