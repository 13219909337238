import { useState, useRef, useCallback, useEffect } from 'react';
import moment from 'moment';
import { Space, Table, Tag, Button, Modal, Row, List, Tooltip, Form, Input, message, Flex, QRCode } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useSelector, useDispatch } from 'react-redux';
import { IApplicationState, serveRequest } from '../../store';
import { PAGE_SIZE, fetchApi } from '../../store/requests';
import { EditOutlined, PrinterOutlined, QrcodeOutlined } from '@ant-design/icons';
import { QrScanner } from '@yudiel/react-qr-scanner';
import { useReactToPrint } from 'react-to-print';
import { ListLoading } from '../../components/ListLoading';
import { ORDER_STATUS_A, ORDER_STATUS_B } from '../../constants';
import { isMobile } from '../../helpers';
import { useNavigate } from 'react-router-dom';
import { OrderCustomFieldType } from './OrderFields';
import { OrderAttachmentType } from './OrderAttachments';

export interface OrderDataType {
	id: string;
	order_code: string;
	description: string;
	status: string[];
	status_a: string;
	status_b: string;
	warning_a: string;
	warning_b: string;
	start_date: string;
	end_date: string;
	modified_date: string;
	created_date: string;
	configuration_id: number;
	fields: OrderCustomFieldType[];
	attachments: OrderAttachmentType[];
}

export const Orders = () => {
	let printRef = useRef<any>(null);
	const me = useSelector((state: IApplicationState) => state.stored.me.data);
	const orders = useSelector((state: IApplicationState) => state.stored.orders);
	const [scan, setScan] = useState('');
	const [requestNextOrder, setRequestNextOrder] = useState<boolean>(false);
	const [print, setPrint] = useState<OrderDataType | null>(null);
	const [page_size, setPageSize] = useState<number>(PAGE_SIZE);
	const [page_index, setPageIndex] = useState<number>(1);
	const [keyword, setKeyword] = useState<string>('');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [formSearch] = Form.useForm();
	useEffect(() => {
		dispatch(serveRequest('orders', { page_index: 1, page_size: PAGE_SIZE }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (formSearch)
			formSearch.setFieldsValue({
				keyword: keyword,
			});
	}, [formSearch, keyword]);

	const handleLoadList = useCallback(
		(pager?: number) => {
			if (orders && !orders.isFetching) {
				if (pager) setPageIndex(pager);
				dispatch(serveRequest('orders', { page_index: pager ? pager : page_index, page_size }));
			}
		},
		[dispatch, page_size, orders, page_index],
	);

	const handleSearch = useCallback(
		(values: any) => {
			if (values.keyword) {
				setKeyword(values.keyword);
				dispatch(serveRequest('orders', { page_index: 1, page_size, order_code: values.keyword }));
			} else if (keyword) {
				handleLoadList(1);
				setKeyword('');
			}
		},
		[dispatch, page_size, handleLoadList, keyword],
	);
	const handleDownloadQr = useCallback(async () => {
		const canvas = document.getElementById('myqrcode')?.querySelector<HTMLCanvasElement>('canvas');
		if (canvas) {
			const url = canvas.toDataURL();
			const a = document.createElement('a');
			a.download = 'QRCode.png';
			a.href = url;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}
	}, []);

	const onScannedCode = useCallback(
		async (code: string) => {
			if (code && !requestNextOrder) {
				setRequestNextOrder(true);
				try {
					const res = await fetchApi('nextOrder', {
						id: Number(code),
						action: 'out',
					});
					if (res) message.success('Chuyển đơn thành công!');
					handleLoadList();
				} catch (e) {
					message.error('Chuyển đơn không thành công!');
				}
				setRequestNextOrder(false);
				setScan('');
			}
		},
		[handleLoadList, requestNextOrder],
	);

	const handlePrint = useReactToPrint({
		content: () => printRef.current,
	});

	if (orders && orders.isFetching) return <ListLoading />;

	const data = orders && orders.data ? orders.data : [];
	const columns: ColumnsType<OrderDataType> = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			defaultSortOrder: 'descend',
			sorter: (a, b) => Number(a.id) - Number(b.id),
			className: 'hide-mobile',
		},
		{
			title: 'Mã đơn',
			dataIndex: 'order_code',
			key: 'order_code',
		},
		{
			title: 'Mô tả',
			dataIndex: 'description',
			key: 'description',
			className: 'hide-mobile',
		},
		{
			title: 'Hiện trạng',
			key: 'status',
			render: (_, record) => {
				let tagA: any = null;
				let tagB: any = null;
				if (record.status_a) {
					const findA = ORDER_STATUS_A.find(t => t.value === record.status_a);
					if (findA) {
						tagA = { ...findA, color: 'green' };
						if (record.warning_a && record.warning_a === 'warning') tagA.color = 'orange';
						if (record.warning_a && record.warning_a === 'error') tagA.color = 'red';
					}
				}
				if (record.status_b && record.status_b !== record.status_a) {
					const findB = ORDER_STATUS_B.find(t => t.value === record.status_b);
					if (findB) {
						tagB = { ...findB, color: 'green' };
						if (record.warning_b && record.warning_b === 'warning') tagB.color = 'orange';
						if (record.warning_b && record.warning_b === 'error') tagB.color = 'red';
					}
				}
				return (
					<Space size={[0, 8]} wrap>
						{tagA ? (
							<Tag key={tagA.id} color={tagA.color}>
								{tagA.label}
							</Tag>
						) : null}
						{tagB ? (
							<Tag key={tagB.id} color={tagB.color}>
								{tagB.label}
							</Tag>
						) : null}
					</Space>
				);
			},
		},
		{
			title: 'Ngày bắt đầu',
			dataIndex: 'start_date',
			key: 'start_date',
			defaultSortOrder: 'descend',
			sorter: (a, b) => moment(a.start_date).unix() - moment(b.start_date).unix(),
			className: 'hide-mobile',
		},
		{
			title: 'Ngày kết thúc',
			dataIndex: 'end_date',
			key: 'end_date',
			defaultSortOrder: 'descend',
			sorter: (a, b) => moment(a.end_date).unix() - moment(b.end_date).unix(),
			className: 'hide-mobile',
		},
		{
			title: 'Ngày tạo',
			dataIndex: 'created_date',
			key: 'created_date',
			defaultSortOrder: 'descend',
			sorter: (a, b) => moment(a.created_date).unix() - moment(b.created_date).unix(),
			className: 'hide-mobile',
		},
		{
			title: 'Tác vụ',
			key: 'action',
			render: (_, record) => (
				<Space size='middle'>
					{me && me.role_id === 'admin' ? (
						<Tooltip title='Sửa đơn'>
							<Button type='default' icon={<EditOutlined />} size='small' onClick={() => navigate('/detail-order/' + record.id)} />
						</Tooltip>
					) : me && me.role_id !== 'ke_toan' ? (
						<Tooltip title='Quét QR'>
							<Button type='default' icon={<QrcodeOutlined />} size='small' onClick={() => setScan(record.id)} />
						</Tooltip>
					) : null}
					<Tooltip title='In QR hóa đơn'>
						<Button type='default' icon={<PrinterOutlined />} size='small' onClick={() => setPrint(record)} />
					</Tooltip>
				</Space>
			),
		},
	];

	return (
		<>
			<Form form={formSearch} name='search' onFinish={handleSearch} autoComplete='off'>
				<Flex style={{ flexWrap: 'wrap', marginBottom: 20 }}>
					<Form.Item name='keyword' style={{ marginBottom: 10 }}>
						<Input placeholder='Tìm theo mã đơn' style={{ minWidth: 200 }} />
					</Form.Item>
					<Button type='default' htmlType='submit' style={{ marginLeft: 10, marginRight: 10 }}>
						Tìm
					</Button>
					<Button type='primary' htmlType='button' onClick={() => navigate('/new-order')}>
						Tạo đơn
					</Button>
				</Flex>
			</Form>
			<Table
				rowKey='id'
				columns={columns}
				dataSource={data}
				pagination={{
					total: orders && orders.total ? orders.total : data.length,
					current: page_index,
					pageSize: page_size,
					onChange: page => {
						handleLoadList(page);
					},
					onShowSizeChange: (current, size) => {
						setPageSize(size);
					},
				}}
				expandable={{
					expandedRowRender: record => (
						<List>
							<List.Item>
								<b>Mô tả:</b> {record.description}
							</List.Item>
							<List.Item>
								<b>Ngày tạo:</b> {record.created_date}
							</List.Item>
							<List.Item>
								<b>Ngày bắt đầu:</b> {record.start_date}
							</List.Item>
							<List.Item>
								<b>Ngày kết thúc:</b> {record.end_date}
							</List.Item>
						</List>
					),
				}}
			/>
			<Modal footer={null} width={840} centered open={!!scan} onOk={() => {}} onCancel={() => setScan('')}>
				<div style={{ padding: isMobile() ? 10 : 40 }}>
					<QrScanner
						containerStyle={{ maxWidth: 800, maxHeight: 800 }}
						onDecode={result => onScannedCode(result)}
						onError={error => console.log(error?.message)}
					/>
				</div>
			</Modal>
			<Modal
				width={isMobile() ? '98vw' : '70vw'}
				centered
				open={!!print}
				footer={[
					<Button key='1' onClick={() => setPrint(null)}>
						Hủy
					</Button>,
					<Button key='2' htmlType='button' type='primary' onClick={handleDownloadQr}>
						Tải QR về
					</Button>,
					<Button key='3' htmlType='button' type='primary' onClick={handlePrint}>
						In
					</Button>,
				]}
				onOk={handlePrint}
				onCancel={() => setPrint(null)}
			>
				{print ? (
					<Row ref={printRef} style={{ padding: isMobile() ? 0 : 40 }}>
						<div id='myqrcode' style={{ textAlign: 'center' }}>
							<QRCode value={print.id} size={isMobile() ? 280 : 320} bgColor='#fff' style={{ marginBottom: 16, width: '50%' }} />
						</div>
						<List style={{ paddingLeft: 20, flexGrow: 1 }}>
							<List.Item>
								<b>Mã đơn:</b> {print.order_code}
							</List.Item>
							<List.Item>
								<b>Mô tả:</b> {print.description}
							</List.Item>
							<List.Item>
								<b>Ngày tạo:</b> {print.created_date}
							</List.Item>
							<List.Item>
								<b>Ngày bắt đầu:</b> {print.start_date}
							</List.Item>
							<List.Item>
								<b>Ngày kết thúc:</b> {print.end_date}
							</List.Item>
						</List>
					</Row>
				) : null}
			</Modal>
		</>
	);
};
