import { useState, useCallback, useEffect } from 'react';
import dayjs from 'dayjs';
import { DatePicker, Button, Form, Select, Input, message, Divider, Row, Col, Typography } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { IApplicationState, resetStore, serveRequest } from '../../store';
import { fetchApi } from '../../store/requests';
import { ListLoading } from '../../components/ListLoading';
import { ORDER_STATUS_A, ORDER_STATUS_B } from '../../constants';
import { useNavigate, useParams } from 'react-router-dom';
import { isMediumDevice, usePrevious } from '../../helpers';
import { OrderDataType } from '.';
import { OrderFields } from './OrderFields';
import { OrderAttachments } from './OrderAttachments';

const { Title } = Typography;

const disabledDate: any['disabledDate'] = (current: any) => {
	return current && current < dayjs().startOf('day');
};

const dateSubmitFormat = 'YYYY-MM-DD';

export const OrderForm = () => {
	const order = useSelector((state: IApplicationState) => state.stored.order);
	const quotaGroups = useSelector((state: IApplicationState) => state.stored.allQuotaGroups);
	const [statusA, setStatusA] = useState<string>('');
	const [saving, setSaving] = useState<boolean>(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [form] = Form.useForm();
	let { orderid } = useParams();
	const lastData = usePrevious(order ? order.data : null);

	useEffect(() => {
		if (orderid) dispatch(serveRequest('order', { id: orderid }));
		dispatch(serveRequest('allQuotaGroups', { page_index: 1, page_size: 100000 }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		return () => {
			dispatch(resetStore('order'));
		};
	}, [dispatch]);

	useEffect(() => {
		if (form && !lastData && order && order.data) {
			const orderDetail = order.data;
			form.setFieldsValue({
				description: orderDetail.description,
				configuration_id: orderDetail.configuration_id,
				status_a: orderDetail.status.length ? orderDetail.status[0] : 'cho_san_xuat',
				status_b: orderDetail.status.length > 1 ? orderDetail.status[1] : '',
			});
		}
	}, [form, lastData, order]);

	const handleSubmitOrder = useCallback(
		async (values: any) => {
			setSaving(true);
			if (orderid) {
				try {
					const res = await fetchApi('editOrder', {
						...values,
						status_b: values.status_b ? values.status_b : values.status_a,
						id: orderid,
					});
					if (res) message.success('Cập nhật đơn thành công!');
					dispatch(resetStore('order'));
					navigate('/orders');
				} catch (e) {
					message.error('Cập nhật đơn không thành công! ' + String(e));
				}
			} else {
				try {
					const res = await fetchApi('addOrder', {
						...values,
						status_b: values.status_b ? values.status_b : values.status_a,
					});
					if (res && res.addOrder) {
						message.success('Tạo đơn thành công!');
						dispatch(serveRequest('order', { id: res.addOrder.id }));
						navigate('/detail-order/' + res.addOrder.id, { replace: true });
					}
				} catch (e) {
					message.error('Tạo đơn không thành công! ' + String(e));
				}
			}
			setSaving(false);
			setStatusA('');
			form.resetFields();
		},
		[orderid, form, dispatch, navigate],
	);

	if (orderid && order && !order.data && order.isFetching) return <ListLoading />;

	const data: OrderDataType | null = order && order.data ? order.data : null;
	let openStatusB = false;
	if (data && data.status.length && !statusA) {
		const statusA = ORDER_STATUS_A.find(t => t.value === data.status[0]);
		if (statusA && statusA.id >= 4 && statusA.id < 14) openStatusB = true;
		if (!openStatusB && data.status.length > 1) {
			const statusB = ORDER_STATUS_B.find(t => t.value === data.status[1]);
			if (statusB) openStatusB = true;
		}
	}
	if (statusA) {
		const StatusA = ORDER_STATUS_A.find(t => t.value === statusA);
		if (StatusA && StatusA.id >= 4 && StatusA.id < 14) {
			openStatusB = true;
		} else {
			openStatusB = false;
		}
	}

	const parents =
		quotaGroups && quotaGroups.data && quotaGroups.data.length
			? quotaGroups.data.map((g: any) => {
					return { value: Number(g.id), label: g.name };
			  })
			: [];
	const mobileCol = isMediumDevice() ? 24 : 12;
	return (
		<>
			<Title level={3}>{orderid ? 'Thông tin đơn hàng' : 'Tạo mới đơn hàng'}</Title>
			<Row>
				<Col span={orderid ? mobileCol : 24}>
					<Form
						name={`order-form`}
						form={form}
						labelCol={{ span: 6 }}
						layout='horizontal'
						onFinish={handleSubmitOrder}
						autoComplete='off'
						style={{ width: '100%', paddingRight: 20 }}
						initialValues={
							data
								? {
										description: data.description,
										configuration_id: data.configuration_id,
										status_a: data.status.length ? data.status[0] : 'cho_san_xuat',
										status_b: data.status.length > 1 ? data.status[1] : '',
										start_date: data.start_date ? dayjs(data.start_date) : undefined,
										end_date: data.end_date ? dayjs(data.end_date) : undefined,
								  }
								: undefined
						}
					>
						<Form.Item
							label='Gói định mức'
							name='configuration_id'
							rules={[{ required: true, message: 'Gói định mức không được trống!' }]}
						>
							<Select style={{ width: '100%' }} options={parents} />
						</Form.Item>

						<Form.Item label='Khâu hiện tại' name='status_a'>
							<Select style={{ width: '100%' }} onChange={val => setStatusA(String(val))} options={ORDER_STATUS_A} />
						</Form.Item>

						{openStatusB ? (
							<Form.Item label='Khâu Inox' name='status_b'>
								<Select style={{ width: '100%' }} options={ORDER_STATUS_B} />
							</Form.Item>
						) : null}

						<Form.Item
							label='Ngày bắt đầu'
							name='start_date'
							rules={[{ required: orderid ? false : true, message: 'Ngày bắt đầu không được trống!' }]}
						>
							<DatePicker format={dateSubmitFormat} disabledDate={!orderid ? disabledDate : undefined} />
						</Form.Item>

						<Form.Item
							label='Ngày kết thúc'
							name='end_date'
							rules={[{ required: orderid ? false : true, message: 'Ngày kết thúc không được trống!' }]}
						>
							<DatePicker format={dateSubmitFormat} disabledDate={disabledDate} />
						</Form.Item>

						<Form.Item label='Mô tả' name='description'>
							<Input.TextArea />
						</Form.Item>

						<Form.Item wrapperCol={{ offset: 6, span: 18 }}>
							<Button type='primary' htmlType='submit' loading={saving} disabled={saving}>
								{orderid ? 'Cập nhật đơn' : 'Thêm mới'}
							</Button>
						</Form.Item>
					</Form>
				</Col>
				{orderid ? (
					<Col span={mobileCol}>
						<OrderAttachments attachments={data ? data.attachments : []} orderid={Number(orderid)} />
					</Col>
				) : null}
				{orderid ? (
					<Col span={mobileCol}>
						<Divider />
						<OrderFields fields={data ? data.fields : []} orderid={Number(orderid)} />
					</Col>
				) : null}
			</Row>
		</>
	);
};
