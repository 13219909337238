import { useEffect, useRef, useState } from 'react';

export function isNumeric(value: any) {
	return /^-{0,1}\d+$/.test(value);
}

export function isDesktop() {
	return window.innerWidth >= 1200;
}
export function isMobile() {
	return window.innerWidth < 767;
}
export function isMediumDevice() {
	return window.innerWidth < 992;
}

export function buildFormData(formData: any, data: any, parentKey?: string) {
	if (data && typeof data === 'object') {
		if (Array.isArray(data) && parentKey) {
			formData.append(parentKey, data);
		} else {
			Object.keys(data).forEach(key => {
				const fieldKey = isNumeric(key) ? '[]' : `[${key}]`;
				buildFormData(formData, data[key], parentKey ? `${parentKey}${fieldKey}` : key);
			});
		}
	} else {
		const value = data === null ? '' : data;
		formData.append(parentKey, value);
	}
}

export function objectToFormData(data: any) {
	const formData = new FormData();
	buildFormData(formData, data);
	return formData;
}

export const formatAmount = (amount: number | string): string => {
	if (!amount) return '0';
	return Math.round(Number(amount))
		.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatNDT = (amount: number | string): string => {
	if (!amount) return '0';
	if (Number(amount) % 1 === 0) return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	const round = Number(amount).toFixed(2);
	if ((Number(round) * 10) % 1 === 0)
		return Number(round)
			.toFixed(1)
			.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	return round.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};
export const useDebounce = (value: any, delay: number) => {
	// State and setters for debounced value
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(
		() => {
			// Update debounced value after delay
			const handler = setTimeout(() => {
				setDebouncedValue(value);
			}, delay);

			// Cancel the timeout if value changes (also on delay change or unmount)
			// This is how we prevent debounced value from updating if value is changed ...
			// .. within the delay period. Timeout gets cleared and restarted.
			return () => {
				clearTimeout(handler);
			};
		},
		[value, delay], // Only re-call effect if value or delay changes
	);

	return debouncedValue;
};

export const usePrevious = (value: any) => {
	const ref = useRef();

	useEffect(() => {
		ref.current = value;
	}, [value]); // Only re-run if value changes

	return ref.current;
};

export const timer = (seconds: number): string => {
	let min = '00';
	let sec = seconds < 10 ? '0' + String(seconds) : String(seconds);
	if (seconds >= 60) {
		const secs = seconds % 60;
		sec = secs < 10 ? '0' + String(secs) : String(secs);
		const mins = Math.floor(seconds / 60);
		min = mins < 10 ? '0' + String(mins) : String(mins);
	}
	return min + ':' + sec;
};

export const randomString = (length: number = 10) => {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}

	return result;
};

export const numberToVnd = (value: string) => {
	if (value) {
		const amount = String(value).split('.');
		const validNumber = Number(amount[0].replace(/\D/g, ''));
		if (String(validNumber).length >= 5) {
			return String(validNumber).replace(/(\d)(?=(\d{3})+$)/g, '$1,');
		} else {
			return String(validNumber);
		}
	}
	return '';
};

export const vndToNumber = (value: string) => {
	if (value) {
		return String(Number(value.replace(/\D/g, '')));
	}
	return '';
};

export const makeid = (length = 10) => {
	var result = '';
	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};
