import { combineReducers } from 'redux';
import { IApplicationState } from './';
import { StoredReducer, SystemMessageReducer } from './reducers';

export const Reducers = combineReducers<IApplicationState>({
	sys: SystemMessageReducer,
	stored: StoredReducer,
});

export * from './state';
