import { useState, useCallback, useEffect } from 'react';
import { Space, Table, Tag, Button, Modal, Tooltip, Form, Input, message, Flex, Switch } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useSelector, useDispatch } from 'react-redux';
import { IApplicationState, serveRequest } from '../../store';
import { PAGE_SIZE, fetchApi } from '../../store/requests';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ListLoading } from '../../components/ListLoading';

interface QuotaGroupDataType {
	id: string;
	enabled: boolean;
	name: string;
}

export const QuotaGroups = () => {
	const items = useSelector((state: IApplicationState) => state.stored.quotaGroups);
	const [edit, setEdit] = useState<QuotaGroupDataType | null>(null);
	const [del, setDel] = useState<QuotaGroupDataType | null>(null);
	const [add, setAdd] = useState<boolean>(false);
	const [saving, setSaving] = useState<boolean>(false);
	const [page_size, setPageSize] = useState<number>(PAGE_SIZE);
	const [page_index, setPageIndex] = useState<number>(1);
	const dispatch = useDispatch();
	const [formEdit] = Form.useForm();
	const [formAdd] = Form.useForm();
	useEffect(() => {
		dispatch(serveRequest('quotaGroups', { page_index: 1, page_size: PAGE_SIZE }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (formEdit && edit)
			formEdit.setFieldsValue({
				enabled: edit.enabled,
				name: edit.name,
			});
	}, [formEdit, edit]);

	const handleLoadList = useCallback(
		(pager?: number) => {
			if (items && !items.isFetching) {
				if (pager) setPageIndex(pager);
				dispatch(serveRequest('quotaGroups', { page_index: pager ? pager : page_index, page_size }));
			}
		},
		[dispatch, page_size, items, page_index],
	);

	const handleEdit = useCallback(
		async (values: any) => {
			if (edit) {
				setSaving(true);
				try {
					const res = await fetchApi('editQuotaGroup', {
						...values,
						id: edit.id,
					});
					if (res) message.success('Cập nhật gói thành công!');
					handleLoadList();
				} catch (e) {
					message.error('Cập nhật gói không thành công! ' + String(e));
				}
				setSaving(false);
				setEdit(null);
				formEdit.resetFields();
			}
		},
		[handleLoadList, edit, formEdit],
	);

	const handleAdd = useCallback(
		async (values: any) => {
			setSaving(true);
			try {
				const res = await fetchApi('addQuotaGroup', {
					...values,
				});
				if (res) message.success('Tạo gói thành công!');
				handleLoadList(1);
			} catch (e) {
				message.error('Tạo gói không thành công! ' + String(e));
			}
			setSaving(false);
			setAdd(false);
			formAdd.resetFields();
		},
		[handleLoadList, formAdd],
	);

	const handleDel = useCallback(async () => {
		if (del) {
			setSaving(true);
			try {
				const res = await fetchApi('delQuotaGroup', {
					id: del.id,
				});
				if (res) message.success('Xóa gói định mức thành công!');
				handleLoadList(1);
			} catch (e) {
				message.error('Xóa gói định mức không thành công! ' + String(e));
			}
			setSaving(false);
			setDel(null);
		}
	}, [handleLoadList, del]);

	if (items && items.isFetching) return <ListLoading />;

	const data = items && items.data ? items.data : [];
	const columns: ColumnsType<QuotaGroupDataType> = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			defaultSortOrder: 'descend',
			sorter: (a, b) => Number(a.id) - Number(b.id),
			className: 'hide-mobile',
		},
		{
			title: 'Tên gói',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Trạng thái',
			dataIndex: 'enabled',
			key: 'enabled',
			render: val => (val === true ? <Tag color='green'>Hoạt động</Tag> : <Tag color='red'>Không hoạt động</Tag>),
		},

		{
			title: 'Tác vụ',
			key: 'action',
			render: (_, record) => (
				<Space size='middle'>
					<Tooltip title='Sửa gói'>
						<Button type='default' icon={<EditOutlined />} size='small' onClick={() => setEdit(record)} />
					</Tooltip>
					<Tooltip title='Xóa'>
						<Button danger icon={<DeleteOutlined />} size='small' onClick={() => setDel(record)} />
					</Tooltip>
				</Space>
			),
		},
	];

	return (
		<>
			<Flex style={{ marginBottom: 20 }}>
				<Button
					type='primary'
					htmlType='button'
					onClick={() => {
						setAdd(true);
						formAdd.setFieldsValue({ enabled: true });
					}}
				>
					Tạo gói
				</Button>
			</Flex>
			<Table
				rowKey='id'
				columns={columns}
				dataSource={data}
				pagination={{
					total: items && items.total ? items.total : data.length,
					current: page_index,
					pageSize: page_size,
					onChange: page => {
						handleLoadList(page);
					},
					onShowSizeChange: (current, size) => {
						setPageSize(size);
					},
				}}
			/>
			<Modal width={640} footer={null} centered open={!!edit} onCancel={() => setEdit(null)}>
				{edit ? (
					<Form
						name={`edit-group`}
						form={formEdit}
						labelCol={{ span: 4 }}
						wrapperCol={{ span: 14 }}
						layout='horizontal'
						style={{ maxWidth: 600, padding: 20 }}
						initialValues={{
							enabled: edit.enabled,
							name: edit.name,
						}}
						onFinish={handleEdit}
						autoComplete='off'
					>
						<Form.Item label='Tên' name='name'>
							<Input />
						</Form.Item>

						<Form.Item label='Hoạt động' name='enabled'>
							<Switch />
						</Form.Item>

						<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
							<Button type='primary' htmlType='submit' loading={saving} disabled={saving}>
								Cập nhật
							</Button>
						</Form.Item>
					</Form>
				) : null}
			</Modal>
			<Modal
				width={640}
				footer={null}
				centered
				open={!!add}
				onCancel={() => {
					setAdd(false);
					formAdd.resetFields();
				}}
			>
				{add ? (
					<Form
						name={`add-groups`}
						form={formAdd}
						labelCol={{ span: 6 }}
						wrapperCol={{ span: 14 }}
						layout='horizontal'
						style={{ maxWidth: 600, padding: 20 }}
						onFinish={handleAdd}
						autoComplete='off'
					>
						<Form.Item label='Tên' name='name' rules={[{ required: true, message: 'Tên gói không được trống!' }]}>
							<Input />
						</Form.Item>

						<Form.Item label='Hoạt động' name='enabled'>
							<Switch />
						</Form.Item>

						<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
							<Button type='primary' htmlType='submit' loading={saving} disabled={saving}>
								Tạo gói
							</Button>
						</Form.Item>
					</Form>
				) : null}
			</Modal>
			<Modal
				width={640}
				centered
				open={!!del}
				onCancel={() => setDel(null)}
				okButtonProps={{ loading: saving, disabled: saving }}
				okText='Có'
				cancelText='Không'
				onOk={handleDel}
			>
				<h4>Bạn có chắc chắn muốn xóa không ?</h4>
			</Modal>
		</>
	);
};
